<template>
  <div class="content__columns">
    <div class="content__column">
      <div v-if="edu_data.count > 0" class="content__title content__title--party content__title--start">
        <h2 class="title">Учебные заведения</h2>
        <router-link
          :to="{ name: 'profile-organization-create' }"
          class="link link--right"
        >
          <span>добавить</span>
        </router-link>
      </div>
      <div class="data-cards data-cards--single">
        <template v-if="edu_data.count < 1">
          <h2 class="content__title title">Образование</h2>
          <router-link :to="{ name: 'profile-organization-create' }" class="data-cards__item data-cards__item--empty">
<!--            <div class="data-cards__title"><span>Образование</span></div>-->
            <div class="data-cards__picture">
              <img src="@/assets/img/profile/mortarboard-def-icon.svg" alt="">
              <img src="@/assets/img/profile/mortarboard-color-icon.svg" alt="">
            </div>
            <div class="data-cards__text">
              На данный момент данные о вашем образовании не заполнены, нажмите сюда, чтобы заполнить
            </div>
          </router-link>
        </template>
        <div v-else class="data-cards__item data-cards__item--indent" v-for="item in edu_data.results" :key="item.id">
          <div class="data-cards__title">
            <span>{{ item.level_name }}</span>
            <router-link :to="{ name: 'profile-organization-edit', params: { id: item.id } }">редактировать</router-link>
          </div>
          <div class="data-cards__picture">
            <img src="@/assets/img/profile/mortarboard-color-icon.svg" alt="">
          </div>
          <div class="data-cards__text"><b>Учебное заведение:</b>  {{ item.org_text }}</div>
          <div class="data-cards__text">
            <b>Период обучения:</b> {{ item.date_start }} - <span v-if="item.date_end">{{ item.date_end }}</span> <span v-if="!item.date_end">по н.в.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content__column">
      <div v-if="lang_data.count > 0" class="content__title content__title--party content__title--start">
        <h2 class="title">Владения языками</h2>
        <router-link :to="{ name: 'profile-language-create' }" class="link link--right">
          <span>добавить</span>
        </router-link>
      </div>
      <div class="data-cards data-cards--single">
        <template v-if="lang_data.count < 1">
          <h2 class="content__title title">Языки</h2>
          <router-link :to="{ name: 'profile-language-create' }" class="data-cards__item data-cards__item--empty">
            <!--          <div class="data-cards__title"><span>Языки</span></div>-->
            <div class="data-cards__picture">
              <img src="@/assets/img/profile/translate-def-icon.svg" alt="">
              <img src="@/assets/img/profile/translate-color-icon.svg" alt="">
            </div>
            <div class="data-cards__text">
              Информация о владении языками не заполнена, нажмите сюда, чтобы добавить ее
            </div>
          </router-link>
        </template>
        <div v-else class="data-cards__item data-cards__item--indent" v-for="item in lang_data.results" :key="item.id">
          <div class="data-cards__title">
            <span>{{ item.lang_name }}</span>
            <router-link :to="{ name: 'profile-language-edit', params: { id: item.id } }">редактировать</router-link>
          </div>
          <div class="data-cards__picture">
            <img src="@/assets/img/profile/translate-color-icon.svg" alt="">
          </div>
          <div class="data-cards__text"><b>Уровень владения:</b> {{ item.level_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileEducationList',
  data() {
    return {
      edu_data: {},
      lang_data: {},
    }
  },
  mounted() {
    this.$store.dispatch('profile/education/GET_ORG_LIST')
      .then(response => {
        this.edu_data = response.data
      })
    this.$store.dispatch('profile/education/GET_LANG_LIST')
      .then(response => {
        this.lang_data = response.data
      })
  },
}
</script>
